import axios from 'axios';
import axiosRetry from 'axios-retry';
// import server from './server.json';

axiosRetry(axios, { retries: 3 , retryDelay: (retryCount) => {
    return retryCount * 1500;
}});

const urlapi = process.env.VUE_APP_URL_API + '/api/produtos';

async function getProdutos(data: any){
    const gprodutos = await axios.get(urlapi + '.json', {
        params: {
            'access_token': data.token.access_token,
            skip: data.sk,
            quantity: data.qt
            }
     })
    .then(res => {
        return res.data;
    })
    .catch((err)=>{
        console.log(err)
    })

    return gprodutos;
}

async function getProdutosDestaque(data: any){
    const dprodutos = await axios.get(urlapi + '/produtosdestaque.json', {
        params: {
            'access_token': data.token.access_token,
            }
     })
    .then(res => {
        return res.data;
    })
    .catch((err)=>{
        console.log(err)
    })

    return dprodutos;
}

async function getProdutosCategoria(data: any){
    const procategoria = await axios.get(urlapi + '/buscacategoria.json', {
        params:{
            'access_token': data.token.access_token,
            skip: data.sk,
            quantity: data.qt,
            catpro_id: data.catpro_id
            }
        })
        .then(res => {
            return res.data;
        })
        .catch((err)=>{
            console.log(err)
        })

    return procategoria;
}

async function getProductSearch(token: any,searchTerm: string){
    const pro = await axios.get(urlapi + '/busca.json', {params: {
            'access_token': token.access_token,
            nome: searchTerm
        }
    }).then((ret)=>{
        // console.log(ret);
        return ret.data;
    })

    return pro;
}



export {
    getProdutos,
    getProdutosCategoria,
    getProductSearch,
    getProdutosDestaque
}