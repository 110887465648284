import { createStore, Store , Commit } from 'vuex';
import { InjectionKey } from 'vue'
import { getToken, 
         getProdutos, 
         getCategorias , 
         getProdutosCategoria , 
         getUserDataByFone, 
         postPedidoData , 
         postClientUpdateData,
         postClientNewInsertData,
         getProductSearch,
         getProdutosDestaque
    } from '../service'



export interface State {
    // useremail: string,
    token: object,
    produtos: object,
    destaques: object,
    categorias: object,
    catpro: object,
    skipper: number,
    cart: Array<any>,
    totalcart: number,
    user: object,
    pedido: object,
    datapedido: object,
    searchterm: string
}
export const key: InjectionKey<Store<State>> = Symbol();

export const store = createStore<State>({
    state: {
      token: {},
      produtos: [],
      categorias: [],
      destaques: [],
      catpro: {},
      cart: [],
      totalcart: 0,
      skipper: 0,
      user: {},
      pedido: {},
      datapedido: {},
      searchterm: ''
    },
    actions: {
        getTokenData({commit}){
            // console.log('get token');
            getToken().then((res)=>{
                // console.log(res);
                commit('setToken', res)
            })
        }, 
        getProdutosData({commit}, data: any){
            // console.log('get Produtos');
            getProdutos(data).then((pro)=>{
                // console.log('tdata = ', data );
                commit('setProdutos', pro)
            })
        },
        getAllProdutosDestaque({commit}, data:any){
            getProdutosDestaque(data).then((pro)=>{
                // const dd = pro.filter((el: any) => {return el.appdestaque === true});
                // console.log(dd);
                commit('SetProdutosDesaques', pro)
                // commit('setProdutos', pro)
            })
        },
        getCategoriasData({commit}, data: any){
            // console.log('get categorias');
            getCategorias(data).then((cat)=>{
                    // console.log('catdata = ', cat );
                    commit('setCategorias', cat);
            })
        },
        placeItenOnCart({commit}, produto: any){
            // console.log(produto);
            commit('addtoCartProduct', produto);
        },
        getProdutosCategoriaData({commit}, data: any){
            getProdutosCategoria(data).then((pro)=>{
                commit('setProdutos', pro)
            })
        },
        postNewCategoria({commit}, cat: object){
            commit('setSelectedCategoria', cat);
        },
        changeSkipper({commit}, skip: number){
            commit('setSkipper', skip)
        },
        getUserFone({commit}, dataft: any){
            // eslint-disable-next-line
            const aa = getUserDataByFone(dataft).then((user: any)=>{

                // console.log('user ret ', user);
                if (user.status == 'notfound'){
                    console.log('no found', dataft );
                    commit('setUserData',  {fone: dataft.fone} );
                    return 'notfound';
                } else {

                    commit('setUserData', user );
                    return user;
                }
            })
            return aa;
        },
        updateClientData({commit, state}, data:any){
            data.user = state.user;
            data.token = state.token;
            // console.log('data for commit store ', data);
            postClientUpdateData(data).then(()=>{
                commit('setUpdateClient', data)
            })
        },
        saveClientNewData({commit, state}, datacame:any){
            datacame.user = state.user;
            datacame.token = state.token;
            postClientNewInsertData(datacame).then((retCli: any)=>{
                // console.log('retorno api post cliente', retCli);
                datacame[0].id = retCli.data.data.id;
                // console.log('cliente user salvo no store ', datacame);
                commit('setNewClient', datacame)
            })
        },
        confirmaPedido({commit, state}, final: any){
            // console.log(final);
            const newpedido: any = {
                final: final,
                itens: state.cart,
                totalpedido: state.totalcart,
                user: state.user,
                token: state.token
            };
            postPedidoData(newpedido).then((ped: any)=>{
                // console.log(ped.data);
                const fullpedido: any = {
                    final: final,
                    itens: state.cart,
                    retorno: ped.data.data
                };
                commit('confirmaPedidoSet', fullpedido)
            })
        },
        searchTermsData({commit, state}, data: string){
            const termmod = data.toLowerCase();
            commit('setSearchTerm', termmod);
            getProductSearch(state.token, termmod).then((res: any)=>{
                // console.log(res);
                commit('setProdutosSearch', res);
            })
        },
        clearUserStore({commit}){
            commit('clearUserData');
        },
        clearProdutosStore({commit}){
            commit('clearProductos');
        },
        clearAllStore({commit}){
            commit('clearAll');
        },
        clearBaseStore({commit}){
            commit('clearBase');
        }
    },
    mutations: {
        setToken(state:any, res:any ){
            state.token = res
        },
        setProdutos(state:any, pro:any){
            // console.log(pro)
            pro.forEach((item: any)=> {
                state.produtos.push(item)
            });
        },
        SetProdutosDesaques(state:any, dd:any){
            // console.log(dd)
            state.destaques = dd;
        },
        setProdutosSearch(state:any, pro:any){
            // console.log(pro);
            state.produtos = [];
            pro.forEach((item: any)=> {
                state.produtos.push(item)
            });
        },
        addtoCartProduct(state: any, prod ){
            function addProToCart(pr: any){
                state.cart.push(pr);
                // console.log(pr);
            }
            const onCart = state.cart.findIndex((item: any)=>
                Number(item.id) === Number(prod.id)
            );
            if (onCart == -1){
                prod.quantItem = 1;
                prod.totalitem = prod.preco;
                addProToCart(prod);
                // console.log(onCart);
                // console.log(state.cart)
            } else {
                const hasPro = state.cart[onCart];
                hasPro.quantItem = hasPro.quantItem + 1;
                hasPro.totalitem = hasPro.preco * hasPro.quantItem;
                state.cart[onCart].quantItem = hasPro.quantItem;
                state.cart[onCart].totalitem = hasPro.totalitem;
                // console.log('hasPro', hasPro);
            }
            state.totalcart = state.totalcart + prod.preco;
            // console.log(state.cart);
        },
        setCategorias(state: any, cat: any){
            state.categorias = cat
        },
        setSelectedCategoria(state: any, catdata: any){
            state.catpro = catdata
        },
        setSkipper(state: any, skip: number){
            state.skipper = skip
        },
        setUserData(state: any, data: any){
            state.user = data
        },
        setUpdateClient(state: any, data: any){
            state.user[0].email = data[0].email;
            state.user[0].cpf = data[0].cpf;

            state.user[1].endereco = data[1].endereco;
            state.user[1].numero = data[1].numero;
            state.user[1].complemento = data[1].complemento;
            state.user[1].referencia = data[1].referencia;
            state.user[1].bairro = data[1].bairro;
            state.user[1].cidade = data[1].cidade;
            state.user[1].uf = data[1].uf;
            state.user[1].cep = data[1].cep;
            state.user[1].teste = 'teste';
        },
        setNewClient(state: any, data: any){
            // const userindexadd = state.user.length - 1;
            state.user = data;
        },
        confirmaPedidoSet(state: any, pedido: any){
            state.datapedido = pedido.final;
            state.pedido = pedido.retorno;
        },
        setSearchTerm(state: any, term: string){
            state.searchterm = term;
        },
        clearUserData(state: any){
            state.user = {};
        },
        clearProductos(state: any){
            state.produtos = [];
        },
        clearAll(state: any){
            state.cart = [];
            state.totalcart = 0;
            state.user = [];
            state.pedido = {};
            state.datapedido = {};
        },
        clearBase(state: any){
            state.cart = [];
            state.totalcart = 0;
            state.pedido = {};
            state.datapedido = {};
        }
    }
});
