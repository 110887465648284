import axios from 'axios';
import server from './server.json';
import moment from 'moment';

const urlapi = process.env.VUE_APP_URL_API + '/api/catpros.json';

async function postPedidoData(data: any){
    // console.log('post pedido API', data);
    const cc = data.itens;
    const cartArray: Array<any> = [];
    function itensAtribute(){
      for (const item of cc) {
        // const ip = {produto_id: '', valoritem: '', quantidade: 0 };
        const ip = { produto_id: '', itemnome: '', valoritem: '', quantidade: 0 };

        ip.produto_id = item.id;
        ip.valoritem = item.preco;
        ip.quantidade = item.quantItem;
        ip.itemnome = item.nome;

        cartArray.push(ip);
    }
      // console.log(myArray);
      return {...cartArray};
    }
    const dtroco = async() =>{
        if (data.final.troco == undefined || data.final.troco == null){
        return '';
          } else {
            return Number(data.final.troco).toFixed(2);
          }
    };
    const dataPedido = {
           "cliente_id": data.user[0].id,
           "itenspedidos_attributes": itensAtribute(),
           "data": data.final.datarealizadopedido,
           "horapedido": data.final.agendamentopedido,
           "horaentrega": moment(data.final.agendamentopedido).add(1, 'h'),
           "entregador_id": null,
           "total": (data.totalpedido).toFixed(2),
           "totalsub": (data.totalpedido).toFixed(2),
//           "desconto": "1",
//           "cupom": "1",
//           "pagamento": "1",
           "meiopagamento": data.final.pagamento,
           "statuspagamento": "processo",
           "statuspedido": "realizado",
           "detalhes": await dtroco(),
           "origem": "app"
       };


    const ped: any = await axios.post(process.env.VUE_APP_URL_API + '/api/pedidos.json', {
      access_token: data.token.access_token,
      pedido: dataPedido 
    },
    {
      headers:{
        'Content-Type':  'application/json'
      }
    }
    ).then((ret)=>{
        // console.log('dataPedido', dataPedido);
        return ret
    }).catch((err)=>{
        console.log(err)
    })

    return ped;
}

export {
    postPedidoData
}